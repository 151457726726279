/*-----[ mixins ]---------------------------------------------------------------
| Define mixins and reusable CSS rulesets here. An example:
|
|   $silver-sand: #c1c0be;
|   $content-inner-bg: white;
|
|   @mixin content-inner-border
|   {
|     border-radius: 5px;
|   }
|
|   @mixin content-shadow
|   {
|     box-shadow: $silver-sand 0 3px 5px;
|   }
|
|   @mixin content-inner-box
|   {
|     @include content-inner-border;
|     @include content-shadow;
|     background-color: $content-inner-bg;
|   }
*/

/*
** Also offer clearfix as a mixin for greater flexibility.
** Clearfix style from <https://css-tricks.com/snippets/css/clear-fix/>
*/
@mixin clearfix {
  &::after {
    display: table;
    clear: both;

    content: '';
  }
}

@mixin keep-together {
  white-space: nowrap;
}

@mixin inline-list($spacing: 0.33em) {
  display: inline;

  margin: 0;

  list-style-type: none;

  li {
    display: inline;

    margin-right: $spacing;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@mixin inline-block-list($spacing: $horizontal-spacing) {
  margin: 0;

  list-style-type: none;

  li {
    display: inline-block;

    margin-right: $spacing;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

// Put this on a UL to get text and bullets colored differently.
@mixin colored-bullets(
  $gutter-size: $horizontal-spacing,
  $bullet-character: '\2022',
  $color: $secondary-text-color
) {
  margin-left: 0;

  &,
  li {
    list-style: none;
  }

  li {
    padding-left: $gutter-size;
  }

  li::before {
    display: inline-block;

    margin-left: -$gutter-size;
    width: $gutter-size;

    color: $color;

    font-size: 1.2em;

    content: $bullet-character;
  }
}

/*----------[ typography ]------------------------------------------------------
| Common font-size/line-height combinations.
*/
@mixin heading-text {
  font-size: $page-heading-text-size;
  line-height: $page-heading-text-line-height;
}

@mixin subheading-text {
  font-size: $subheading-text-size;
  line-height: $subheading-text-line-height;
}

@mixin large-text {
  font-size: $large-text-size;
  line-height: $large-text-line-height;
}

@mixin body-text {
  font-size: $body-text-size;
  line-height: $body-text-line-height;
}

@mixin secondary-text {
  font-size: $secondary-text-size;
  line-height: $secondary-text-line-height;
}

@mixin small-text {
  font-size: $small-text-size;
  line-height: $small-text-line-height;
}

/*----------[ forms ]-----------------------------------------------------------
*/
@mixin placeholder-text {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

/*----------[ photos/images ]---------------------------------------------------
*/

/*
** Detect high DPI devices/browsers. Courtesy of
** https://gist.github.com/marcedwards/3446599 and
** https://medium.com/@micjamking/hd-retina-display-media-queries-b5562b5430d6
*/
@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (-o-min-device-pixel-ratio: 1.25/1),
    only screen and (min-resolution: 200dpi),
    only screen and (min-resolution: 1.25dppx) {
    @content;
  }
}

/*
** Mixin for including a retina image as a background.
**
** Parameters:
**  $image-name: The filename of the image without the extension.
**  $image-width: The width of the 1x image.
**  $image-height: The height of the 1x image.
**  $type: The extension of the image file. Defaults to 'png.'
**  $bg-color: The background color. Defaults to 'transparent.'
**  $bg-repeat: The background-repeat setting. Defaults to 'no-repeat.'
**  $bg-pos-x: The horizontal position of the background. Defaults to 'center.'
**  $bg-pos-y: The vertical position of the background. Defaults to 'center.'
**
*/
@mixin retina-background(
  $image-name, $image-width, $image-height, $type: 'png',
  $bg-color: transparent, $bg-repeat: no-repeat,
  $bg-pos-x: center, $bg-pos-y: center
) {
  $full-image-name: '#{$image-name}.#{$type}';
  $retina-image-name: '#{$image-name}@2x.#{$type}';

  background: $bg-color image-url($full-image-name) $bg-repeat $bg-pos-x $bg-pos-y;

  @include retina {
    background-image: image-url($retina-image-name);
    background-size: $image-width $image-height;
  }
}

@mixin retina-graphic($image, $type: 'png') {
  $img-width: image-width('#{$image}.#{$type}');
  $img-height: image-height('#{$image}.#{$type}');

  @include retina-background($image, $img-width, $img-height, $type);
  width: $img-width;
  height: $img-height;
}

/*----------[ parallax ]--------------------------------------------------------
| Mixin to do pure-CSS parallax scrolling effects.
| From http://keithclark.co.uk/articles/pure-css-parallax-websites/
*/
@function parallax-scale-correction($translate-z, $perspective) {
  @return (1 + ($translate-z * -1) / $perspective);
}

@mixin parallax($parallax-perspective: 300px) {
  perspective: $parallax-perspective;

  overflow-x: hidden;
  overflow-y: auto;

  height: 100vh;

  .parallax-group {
    position: relative;

    height: 100vh;
    transform-style: preserve-3d;

    transition: transform 0.5s;

    // See what's really going on…
    &.debug {
      transform: translate3d(700px, 0, -800px) rotateY(30deg);

      .parallax-layer {
        opacity: 0.9;
        box-shadow: 0 0 0 2px #000;
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .parallax-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.fore {
      z-index: 1;

      transform: translateZ(90px) scale(parallax-scale-correction(90px, $parallax-perspective));
    }

    &.base {
      z-index: 4;

      transform: translateZ(0);
    }

    &.back {
      z-index: 3;

      transform: translateZ(-300px) scale(parallax-scale-correction(-300px, $parallax-perspective));
    }

    &.deep {
      z-index: 2;

      transform: translateZ(-600px) scale(parallax-scale-correction(-600px, $parallax-perspective));
    }
  }
}

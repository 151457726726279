/*----------[ alerts ]----------------------------------------------------------
*/
.alerts {
  margin: 0;

  list-style-type: none;

  .message {
    display: flex;

    margin-bottom: $vertical-nudge;
    border: 1px solid transparent;
    border-radius: $default-border-radius;

    .icon,
    .message-content {
      display: inline-block;
    }

    .icon {
      @include fa-icon;

      @extend .fas;
      @extend .fa-fw;

      border-top-left-radius: $default-border-radius;
      border-bottom-left-radius: $default-border-radius;
      padding-top: $vertical-nudge;
      width: 2 * $horizontal-spacing;

      font-size: 0;

      text-align: center;

      &::before {
        display: inline-block;

        position: relative;

        margin-right: 0.5 * $horizontal-spacing;
        margin-left: 0.5 * $horizontal-spacing;
      }
    }

    .message-content {
      padding: $vertical-nudge $horizontal-spacing / 2;
    }

    &.success {
      border-color: $success-feedback;

      .icon {
        @extend .fa-check;

        background-color: $success-feedback-bg;

        &::before {
          top: 2px;

          color: $success-feedback;

          font-size: $large-text-size;
        }
      }
    }

    &.warning {
      border-color: $warning-feedback;

      .icon {
        @extend .fa-asterisk;

        background-color: $warning-feedback-bg;

        &::before {
          top: 3px;

          color: $warning-feedback;

          font-size: $large-text-size;
        }
      }
    }

    &.error {
      border-color: $error-feedback;

      background: inherit;

      .icon {
        @extend .fa-exclamation-triangle;

        background-color: $error-feedback-bg;

        &::before {
          top: 2px;

          color: $error-feedback;

          font-size: $large-text-size;
        }
      }
    }

    &.info {
      border-color: $info-feedback;

      .icon {
        @extend .fa-info-circle;

        background-color: $info-feedback-bg;

        &::before {
          top: 3px;

          color: $info-feedback;

          font-size: $large-text-size;
        }
      }
    }
  }
}

/*-----[ colors ]---------------------------------------------------------------
| Variables defining the colors to be used throughout the application.
*/

/*----------[ table of contents ]-----------------------------------------------
| palette
| backgrounds and borders
| text
| forms
*/

/*----------[ palette ]---------------------------------------------------------
| Use color names you can easily remember here, like "light-blue". Doing this
| makes it easier for you to tweak the exact shade of "light-blue" you're using
| transparently.
*/
// Main palette
$aqua: #97dcd2;
$ocean: #50a197;
$teal: #2e6e66;
$terra-cotta: #f0a47b;
$rust: #a95f39;
$warm-brown: #613924;
$oreo-milkshake: #bda497;
$chocolate-ice-cream: #92715f;
$espresso: #433931;
$tapioca: #ebe9bd;
$coriander: #bbb88d;
$peat: #787652;
$lichen: #bde3bd;
$bay-leaf: #8db88d;
$faded-green: #508d52;
$mint: #78c09c;
$viridian: #4a8869;
$forest-green: #296145;

// Greys
$charcoal: #545153;
$grey: #858083;
$silver: #c5c0c2;
$smoke: #f0eef0;

/*----------[ backgrounds and borders ]-----------------------------------------
| From this point on, we create semantic variables and assign our color values
| to them. Then in the rest of our styles, we refer to these semantic color
| values like so:
|
|   body
|   {
|      background-color: $page-bg;
|      color: $body-text-color;
|   }
|
| By adding this extra layer of abstraction, we can swap out our
| page background or link color—or change the palette entirely—in just one
| place without a lot of tedious search-and-replace.
*/
$page-bg: $smoke;
$accent-bg: white;

$stroke: darken($silver, 15);
$light-stroke: darken($smoke, 10);
$disabled-stroke: $silver;

/*----------[ text ]------------------------------------------------------------
*/
$body-text-color: $charcoal;
$secondary-text-color: $grey;
$light-text-color: lighten($grey, 15);
$disabled-text-color: $silver;

$link: $peat;
$visited-link: $coriander;

$highlighted-text-color: white;
$heading-text-color: $bay-leaf;
$subheading-text-color: $rust;

$success-feedback: $forest-green;
$success-feedback-bg: lighten(desaturate($mint, 15), 22);
$warning-feedback: $peat;
$warning-feedback-bg: $tapioca;
$error-feedback: $rust;
$error-feedback-bg: lighten(desaturate($terra-cotta, 15), 20);
$info-feedback: $teal;
$info-feedback-bg: lighten(desaturate($aqua, 10), 15);

/*----------[ header ]----------------------------------------------------------
*/
$header-bg: white;
$header-border-color: $light-stroke;

/*----------[ forms ]-----------------------------------------------------------
*/
$button-base-bg: $chocolate-ice-cream;
$button-hover-bg: darken($chocolate-ice-cream, 10);
$button-text-color: white;

$secondary-button-base-bg: white;
$secondary-button-hover-bg: lighten($chocolate-ice-cream, 35);
$secondary-button-border-color: $chocolate-ice-cream;
$secondary-button-text-color: $chocolate-ice-cream;

$disabled-button-base-bg: lighten($silver, 3);
$disabled-button-text-color: lighten($grey, 5);

$form-section-heading-text-color: $viridian;

/*----------[ pagination ]------------------------------------------------------
*/
$pagination-bg: lighten(desaturate($oreo-milkshake, 5), 15);

/*----------[ home ]------------------------------------------------------------
*/
$address-icon-color: $rust;

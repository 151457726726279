/*-----[ home ]------------------------------------------------------------
| Styles for the home page
*/
#home {
  background: $page-bg url(/images/leaves.jpg) right bottom fixed no-repeat;
  background-size: contain;

  .page {
    margin: 0;
    padding: $gutter-width;

    min-height: 400px;

    background: linear-gradient(to right bottom, $smoke 0%, rgba($smoke, 0) 100%);

    @media (min-width: $screen-md-min) {
      padding: 2 * $gutter-width;
    }
  }

  .phone {
    .icon {
      @include fa-icon;

      @extend .fas;
      @extend .fa-fw;

      color: $address-icon-color;

      vertical-align: baseline;
    }

    &.work .icon {
      @extend .fa-phone;
    }

    &.fax .icon {
      @extend .fa-fax;
    }
  }

  footer {
    margin-top: $section-spacing;

    p {
      margin: 0;

      color: $secondary-text-color;

      @include secondary-text();
    }
  }

  .p-adr {
    p {
      margin: 0;
    }

    .adr-line-2 {
      margin-bottom: $body-text-line-height;
    }

    .phone {
      margin-bottom: $vertical-nudge;
    }
  }

  .map-container {
    box-sizing: border-box;

    margin-top: $section-spacing;
    border: 1px solid $light-stroke;
    padding: $vertical-spacing / 2 $horizontal-spacing / 2;

    width: max-content;
    max-width: 100%;
    height: max-content;

    background: $accent-bg;
  }

  #map {
    box-sizing: border-box;

    max-width: 100%;
    height: 300px;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }

    @media (min-width: $screen-sm-min) {
      min-width: 500px;
      min-height: 500px;
    }
  }
}
